<template>
    <div>
        <div class="content-right-wrapper">
            <div class="right-title">商品建议</div>
        </div>
        <table>

            <tr>
                <td class="td-content">
                    <div class="img-content-cls">
                        <div class="image-big-box" v-for="(item, i) in planInfo.imageList" :key="i">
                            <img style="width: auto; height: auto; max-width: 100%; max-height: 100%;"
                                @click="previewImg(i)" :src="item" />
                            <div class="delIcon" @click="delImg(i)" v-if="planInfo.status === 1">X</div>
                        </div>
                        <div v-if="(!planInfo.imageList || planInfo.imageList.length < 5) && (!planInfo.status || planInfo.status === 1)"
                            style="display: inline-block; margin: 5px 10px;">
                            <img style="width: 150px; height: 150px;" src="./shangchuantupian.png"
                                @click="clickImgUpload" />
                            <input ref="imgUploadInput" type="file" accept="image/*" @change="uploadImg($event)"
                                style="display: none">
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="td-content">
                    <textarea v-model="planInfo.remarks" class="td-content-text" placeholder="写下您建议的商品,也可以直接点击或上传图片~"
                        :disabled="planInfo.status && planInfo.status > 1" />
                </td>
            </tr>
        </table>
        <div style="text-align: left;">
            <div class="btn-cls" @click="preSubmit" v-if="!planInfo.status || planInfo.status === 1">提交</div>
            <!-- <div class="btn-cls" @click="$router.go(-1)" v-if="!planInfo.status || planInfo.status === 1">返回</div> -->
        </div>
    </div>
</template>

<script>

import { AccountStorage } from "@services/account";

const accountStorage = new AccountStorage();
export default {
    name: "plan-book-detail",
    data() {
        return {
            imgs: '',//图片地址
            previewImgInfo: {
                imgList: [], // 所有图片数组
                index: 0, // 当前点击的图片的索引
                infinite: false,  // 是否可以循环切换
                popup: false // 弹窗的显示隐藏
            },
            planInfo: {
                planId: '',
                imageList: [],
                fileList: [],
                remarks: '',
                dealResult: '',
                dealTime: '',
                orderNo: '',
                createTime: '',
                status: 1,
                resultImg: '',
                deviceType: 1
            },
            previewImgShow: false,
            previewFileShow: false,
            previewFileUrl: '',
            previewFileDownLoadUrl: ''
        }
    },
    mounted() {
        let planId = this.$route.params.id;
        if (planId) {
            this.queryPlanDetails(planId);
        }
    },
    methods: {
        clickImgUpload() {
            this.$refs.imgUploadInput.click();
        },

        async uploadImg(event) {
            let formData = new FormData();
            formData.append('file', event.target.files[0]);
            formData.append('user', 'test');
            const res = await this.$getHttpClient().post('/pjyy-deepexi-resource-center/api/v1/obs/uploadFile', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data;'
                },
            });

            console.log("上传成功", res);
            if (res.data.code == 200) {
                let toJsonRes = res.data; //获取到后台处理过的地址
                this.imgs = res.data.data;
                let list = [];
                list.push(toJsonRes.data);
                if (this.planInfo.imageList.length < 10) {
                    this.planInfo.imageList = this.planInfo.imageList.concat(list);
                }
            }
        },

        previewImg(index) {
            this.previewImgInfo.imgList = this.planInfo.imageList;
            this.previewImgInfo.index = index;
            this.previewImgShow = true;
            this.previewImgInfo.popup = true;
        },

        closePreviewImgShow() {
            this.previewImgShow = false;
        },

        //删除img
        delImg(i) {
            this.$confirm('确定要删除文件吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                // 成功
                this.planInfo.imageList.splice(i, 1);
            }).catch(err => {
                // 失败
            })
        },

        clickFileUpload() {
            this.$refs.fileUploadInput.click();
        },

        async uploadFile(event) {
            let formData = new FormData();
            formData.append('file', event.target.files[0]);
            formData.append('user', 'test');
            const res = await this.$getHttpClient().post('/pjyy-deepexi-resource-center/api/v1/obs/uploadFile', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data;'
                },
            });

            if (res.data.code == 200) {
                let toJsonRes = res.data; //获取到后台处理过的地址
                let list = [];
                list.push({
                    fileUrl: toJsonRes.data,
                    fileName: event.target.files[0].name
                })
                this.planInfo.fileList = this.planInfo.fileList.concat(list);
            }
        },

        previewFile(fileUrl) {
            this.previewFileUrl = '';
            this.previewFileDownLoadUrl = '';
            this.previewFileDownLoadUrl = fileUrl;
            this.previewFileUrl = 'https://view.officeapps.live.com/op/view.aspx?src=' + fileUrl;
            this.previewFileShow = true;
        },

        downLoadPreviewFile() {
            window.open(this.previewFileDownLoadUrl, '_blank');
        },

        closePreviewFileShow() {
            this.previewFileShow = false;
        },

        //删除文件
        delFile(i) {
            this.$confirm('确定要删除文件吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                // 成功
                this.planInfo.fileList.splice(i, 1);
            }).catch(err => {
                // 失败
            })
        },

        async queryPlanDetails(planId) {
            console.log('传入参数', planId);
            const res = await this.$getHttpClient().get('/pjyy-deepexi-product-center/api/v3/MyPlan/getById?id=' + planId);

            if (res.data.code == 200) {
                this.planInfo = {
                    planId: res.data.data.id,
                    status: res.data.data.status,
                    orderNo: res.data.data.orderNo,
                    dealResult: res.data.data.dealResult,
                    dealTime: res.data.data.dealTimeStr,
                    createTime: res.data.data.createTimeStr,
                    remarks: res.data.data.remark,
                    statusName: res.data.data.statusName,
                    deviceType: res.data.data.deviceType,
                    imageList: [],
                    fileList: []
                };
                if (this.planInfo.statusName) {
                    this.planInfo.statusName = this.planInfo.statusName.split(',')[0];
                }

                if (res.data.data.lstFile.length > 0) {
                    res.data.data.lstFile.forEach(lstFle => {
                        if (lstFle.fileType === 1) {
                            // 图片
                            this.planInfo.imageList.push(lstFle.fileUrl);
                        } else {
                            // 文件
                            let fileUrlArr = lstFle.fileUrl.split('/');
                            this.planInfo.fileList.push({
                                fileUrl: lstFle.fileUrl,
                                fileName: fileUrlArr[fileUrlArr.length - 1]
                            });
                        }
                    });
                }
            }

        },

        preSubmit() {
            // 判断填写参数是否为空
            if ((this.planInfo.remarks == undefined || this.planInfo.remarks.trim() == '')
                && this.planInfo.imageList.length <= 0
                && this.planInfo.fileList.length <= 0) {
                this.$messageTip("请填写内容", 'warning')
                return;
            }

            this.$confirm('确认立即提交吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                // 成功
                this.confirmSubmit();
            }).catch(err => {
                // 失败
            })
        },

        // 确认提交计划本
        async confirmSubmit() {
            let lstFile = [];
            if (this.planInfo.imageList.length > 0) {
                this.planInfo.imageList.forEach(img => {
                    lstFile.push({
                        fileUrl: img,
                        // 1-图片 2-文件
                        fileType: 1
                    });
                });
            }
            if (this.planInfo.fileList.length > 0) {
                this.planInfo.fileList.forEach(fl => {
                    lstFile.push({
                        fileUrl: fl.fileUrl,
                        // 1-图片 2-文件
                        fileType: 2
                    });
                });
            }
            if ((this.planInfo.remarks == undefined || this.planInfo.remarks.trim() == '') && lstFile.length <= 0) {
                this.$messageTip("请填写内容", 'warning')
                return;
            }
            let url = "/pjyy-deepexi-aggregation/api/v1/complaint/appSave"
            let data = {
                customerId: '',
                customerName: '',
                suggestStatus: 7,
                suggestContent: this.planInfo.remarks,
                imageUrl: this.imgs
            }
            let userInfo = accountStorage.getCacheUserInfo()
            data.customerId = userInfo.account
            data.customerName = userInfo.userName

            let result = await this.$getHttpClient().post(url, data)

            if (result.data.code == "200") {

                this.$router.go(-1)
                this.$messageTip("提交成功", 'success')
            }
        },
    }
}
</script>

<style src="./plan-book-detail.less" lang="less" scoped>

</style>
