// 上传图片组件
import mUpLoader from '../../../components-v2/uploader/uploader.vue';
export default {
  name: 'UploadImg',
  components: {
    mUpLoader: mUpLoader
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {
    //
  },
  methods: {
    //
  }
};